import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import NavbarInteractive from '../../components/navbar-interactive'
import './eula.css'

const Eula = (props) => {
  return (
    <div className="eula-container">
      <Helmet>
        <title>Heist - EULA</title>
        <meta property="og:title" content="Heist - EULA" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <NavbarInteractive rootClassName="navbar-interactive-root-class-name"></NavbarInteractive>
      <div className="eula-container1">
        <span className="eula-text">End User License Agreement</span>
      </div>
      <div className="eula-container2">
        <div className="eula-container3">
          <div className="eula-container4">
            <Script
              html={`<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap') @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
</style>
<style>
[data-custom-class='body'],
[data-custom-class='body'] * {
    background: transparent !important;
}

[data-custom-class='title'],
[data-custom-class='title'] * {
    font-family: Poppins !important;
    font-size: 26px !important;
    color: #000000 !important;
}

[data-custom-class='subtitle'],
[data-custom-class='subtitle'] * {
    font-family: Poppins !important;
    color: #595959 !important;
    font-size: 14px !important;
}

[data-custom-class='heading_1'],
[data-custom-class='heading_1'] * {
    font-family: Poppins !important;
    font-size: 19px !important;
    color: #000000 !important;
}

[data-custom-class='heading_2'],
[data-custom-class='heading_2'] * {
    font-family: Poppins !important;
    font-size: 17px !important;
    color: #000000 !important;
}

[data-custom-class='body_text'],
[data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Poppins !important;
}

[data-custom-class='link'],
[data-custom-class='link'] * {
    color: #3030F1 !important;
    font-size: 14px !important;
    font-family: Poppins !important;
    word-break: break-word !important;
}
</style>
<div data-custom-class="body">
    <div align="center" style="text-align: left;">
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div align="center" class="MsoNormal" style="text-align:center;line-height:150%;"><a name="_2cipo4yr3w5d"></a>
                <div align="center" class="MsoNormal" style="line-height: 22.5px;">
                    <div align="center" class="MsoNormal" style="line-height: 150%;"><a name="_gm5sejt4p02f"></a>
                        <div align="center" class="MsoNormal" data-custom-class="title" style="text-align: left; line-height: 1.5;"><strong><span style="line-height: 22.5px; font-size: 26px;">END USER <bdt class="block-component"></bdt>LICENSE<bdt class="statement-end-if-in-editor"></bdt> AGREEMENT</span></strong></div>
                        <div align="center" class="MsoNormal" style="line-height: 22.5px; text-align: left;"><a name="_7m5b3xg56u7y"></a></div>
                    </div>
                </div>
                <div align="center" class="MsoNormal" style="line-height: 17.25px; text-align: left;"><br></div>
                <div align="center" class="MsoNormal" style="line-height: 17.25px; text-align: left;"><span style="font-size: 11pt; line-height: 16.8667px;"><strong><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);">Last updated</span> </span></strong>
                        <bdt class="question"><strong><span data-custom-class="body_text">Jan 17, 2025</span></strong></bdt>
                    </span></div>
                <div align="center" class="MsoNormal" style="line-height: 17.25px; text-align: left;"><br></div>
                <div align="center" class="MsoNormal" style="line-height: 17.25px; text-align: left;"><span style="font-size: 11pt; line-height: 16.8667px;"><br></span></div>
            </div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;"><br></div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 115%;"><a name="_a7mwfgcrtsqn"></a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                    <bdt class="question">Heist</bdt> is licensed to You (End-User) by <bdt class="question">Steal A Minute, Inc.</bdt>, located<bdt class="block-component"></bdt> and registered<bdt class="statement-end-if-in-editor"></bdt> at <bdt class="question">38 Glen Cove Rd</bdt>,<span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                        <bdt class="block-component"></bdt>
                        <bdt class="question">Greenvale</bdt>, <bdt class="block-component"></bdt>
                        <bdt class="question">New York</bdt>
                        <bdt class="block-component"></bdt>
                        <bdt class="question">11548</bdt><span style="font-size: 15px;">
                            <bdt class="block-component"></bdt>
                            <bdt class="block-component"></bdt>, <bdt class="question">United States</bdt>
                            <bdt class="statement-end-if-in-editor"></bdt>
                            <bdt class="block-component"></bdt> 
                        </span>
                    </span>(<bdt class="block-component"></bdt>"<strong>Licensor</strong>"<bdt class="statement-end-if-in-editor"></bdt>), for use only under the terms of this <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement.<bdt class="block-component"></bdt>
                    </bdt>
                </span></bdt></span></span></span></span></span></span></span><span style="font-size: 15px; line-height: 115%; font-family: Poppins; color: rgb(89, 89, 89);"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                                        <bdt class="block-component"></bdt>
                                    </span></span></span></span></span></span></span></div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">By downloading the Licensed Application from <bdt class="block-component"></bdt>Apple's software distribution platform (<bdt class="block-component"></bdt>"App Store"<bdt class="statement-end-if-in-editor"></bdt>)<bdt class="statement-end-if-in-editor"></bdt>
                    <bdt class="block-component"></bdt> and <bdt class="statement-end-if-in-editor"></bdt>
                    <bdt class="block-component"></bdt>Google's software distribution platform (<bdt class="block-component"></bdt>"Play Store"<bdt class="statement-end-if-in-editor"></bdt>)<bdt class="statement-end-if-in-editor"></bdt>, and any update thereto (as permitted by this <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement), You indicate that You agree to be bound by all of the terms and conditions of this <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement, and that You accept this <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement. <bdt class="block-component"></bdt>App Store and Play Store are<bdt class="block-component"></bdt> referred to in this <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement as <bdt class="block-component"></bdt>"<strong>Services</strong>."<bdt class="statement-end-if-in-editor"></bdt>
                </span></div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">The parties of this <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement acknowledge that the Services are not a Party to this <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement and are not bound by any provisions or obligations with regard to the Licensed Application, such as warranty, liability, maintenance and support thereof. <bdt class="question">Steal A Minute, Inc.</bdt>, not the Services, is solely responsible for the Licensed Application and the content thereof.</span></div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
                <bdt data-type="conditional-block"><span style="font-size: 15px;">
                        <bdt data-type="body"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">This <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement may not provide for usage rules for the Licensed Application that are in conflict with the latest <bdt class="block-component"></bdt></span></bdt>
                    </span></bdt><a data-custom-class="link" href="https://www.apple.com/legal/internet-services/itunes/us/terms.html" rel="noopener noreferrer" target="_blank">
                    <bdt data-type="conditional-block"><span style="font-size: 15px;">
                            <bdt data-type="body"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">Apple Media Services Terms and Conditions</span></bdt>
                        </span></bdt>
                </a>
                <bdt data-type="conditional-block"><span style="font-size: 15px;">
                        <bdt data-type="body"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="statement-end-if-in-editor"></bdt>
                                <bdt class="block-component"></bdt> and <bdt class="statement-end-if-in-editor"></bdt>
                                <bdt class="block-component"></bdt>
                            </span></bdt>
                    </span></bdt><a data-custom-class="link" href="https://play.google.com/intl/en_US/about/play-terms/" rel="noopener noreferrer" target="_blank">
                    <bdt data-type="conditional-block"><span style="font-size: 15px;">
                            <bdt data-type="body"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">Google Play Terms of Service</span></bdt>
                        </span></bdt>
                </a>
                <bdt data-type="conditional-block"><span style="font-size: 15px;">
                        <bdt data-type="body"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="statement-end-if-in-editor"></bdt> (<bdt class="block-component"></bdt>"<strong>Usage Rules</strong>"<bdt class="statement-end-if-in-editor"></bdt>).
                            </span></bdt>
                    </span></bdt>
                <bdt data-type="conditional-block"><span style="font-size: 15px;">
                        <bdt data-type="body"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="question">Steal A Minute, Inc.</bdt> acknowledges that it had the opportunity to review the Usage Rules and this <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement is not conflicting with them.
                            </span></bdt>
                    </span></bdt>
            </div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
                <bdt class="question">Heist</bdt>
                <bdt data-type="conditional-block"><span style="font-size: 15px;">
                        <bdt data-type="body"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">when purchased or downloaded through the Services, is licensed to You for use only under the terms of this <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement. The Licensor reserves all rights not expressly granted to You. <bdt class="question">Heist</bdt> is to be used on devices that operate with <bdt class="block-component"></bdt>Apple's operating systems (<bdt class="block-component"></bdt>"iOS" and "Mac OS"<bdt class="statement-end-if-in-editor"></bdt>)<bdt class="statement-end-if-in-editor"></bdt>
                                <bdt class="block-component"></bdt> or <bdt class="statement-end-if-in-editor"></bdt>
                                <bdt class="block-component"></bdt>Google's operating system (<bdt class="block-component"></bdt>"Android"<bdt class="statement-end-if-in-editor"></bdt>)<bdt class="statement-end-if-in-editor"></bdt>.
                            </span></bdt>
                    </span></bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;"><strong><span style="font-size: 19px;">TABLE OF CONTENTS</span></strong></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#application">1. THE APPLICATION</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#scope">2. SCOPE OF <bdt class="block-component"></bdt>LICENSE<bdt class="statement-end-if-in-editor"></bdt></a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#requirements">3. TECHNICAL REQUIREMENTS</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
                <bdt class="block-component"></bdt><a data-custom-class="link" href="#support">4. MAINTENANCE AND SUPPORT</a>
                <bdt class="else-block"></bdt>
            </div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
                <bdt class="block-component"></bdt><a data-custom-class="link" href="#datause">5. USE OF DATA</a>
                <bdt class="statement-end-if-in-editor"></bdt>
            </div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#ugc">6. USER-GENERATED CONTRIBUTIONS</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#contribution">7. CONTRIBUTION <bdt class="block-component"></bdt>LICENSE<bdt class="statement-end-if-in-editor"></bdt></a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#liability">8. LIABILITY</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#warranty">9. WARRANTY</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#productclaims">10. PRODUCT CLAIMS</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#compliance">11. LEGAL COMPLIANCE</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#contact">12. CONTACT INFORMATION</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#termination">13. TERMINATION</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#thirdparty">14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#ipr">15. INTELLECTUAL PROPERTY RIGHTS</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#law">16. APPLICABLE LAW</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#urp">17. USERNAME REVOCATION POLICY</a></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><a data-custom-class="link" href="#misc">18. MISCELLANEOUS</a></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="heading_1" id="application" style="line-height: 1.5;"><a name="_4rd71iod99ud"></a><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>1.</strong><strong> THE APPLICATION</strong></span></span></strong></div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                        <bdt class="question">Heist</bdt>
                    </span> (<bdt class="block-component"></bdt>"<strong>Licensed Application</strong>"<bdt class="statement-end-if-in-editor"></bdt>) is a piece of software created to <bdt class="question">Enable users to self-publish audio clips, and discover and share audio clips.</bdt> — and <bdt class="block-component"></bdt>customized<bdt class="statement-end-if-in-editor"></bdt> for <bdt class="block-component"></bdt>iOS<bdt class="statement-end-if-in-editor"></bdt>
                    <bdt class="block-component"></bdt> and <bdt class="statement-end-if-in-editor"></bdt>
                    <bdt class="block-component"></bdt>Android<bdt class="statement-end-if-in-editor"></bdt> mobile devices (<bdt class="block-component"></bdt>"<strong>Devices</strong>"<bdt class="statement-end-if-in-editor"></bdt>). It is used to <bdt class="question">Enable users to self-publish audio clips, and discover and share audio clips.</bdt>.<bdt class="block-component"></bdt>
                </span></span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.1;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                        <bdt class="statement-end-if-in-editor">
                            <bdt class="block-component"></bdt>
                            <bdt class="block-component"></bdt>
                        </bdt>
                    </span></span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">The Licensed Application is not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions would be subjected to such laws, you may not use this Licensed Application. You may not use the Licensed Application in a way that would violate the Gramm-Leach-Bliley Act (GLBA).</span>
                <bdt class="block-component"></bdt>
                <bdt class="statement-end-if-in-editor"></bdt></span></span>
            </div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" id="scope" style="line-height: 1.5;"><a name="_vhkegautf00d"></a><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>2. SCOPE OF <bdt class="block-component"></bdt>LICENSE<bdt class="statement-end-if-in-editor"></bdt></strong></span></span></strong></span></strong></div>
            <div class="MsoNormal" style="line-height: 115%;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"></span></span>
                    <bdt class="block-component"></bdt>
                </span></div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">2.1  You are given a non-transferable, non-exclusive, non-sublicensable <bdt class="block-component"></bdt>license<bdt class="statement-end-if-in-editor"></bdt> to install and use the Licensed Application on any Devices that You (End-User) own or control and as permitted by the Usage Rules, with the exception that such Licensed Application may be accessed and used by other accounts associated with You (End-User, The Purchaser) via Family Sharing or volume purchasing.</span></bdt>
                                                </bdt>
                                            </bdt>
                                        </span></bdt>
                                </bdt>
                            </bdt>
                        </span><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                                <bdt class="statement-end-if-in-editor">
                                    <bdt class="block-component"></bdt>
                                </bdt>
                            </span></span></span></span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">2<bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">.<bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">2  This <bdt class="block-component"></bdt>license<bdt class="statement-end-if-in-editor"></bdt> will also govern any updates of the Licensed Application provided by Licensor that replace, repair, and/or supplement the first Licensed Application, unless a separate <bdt class="block-component"></bdt>license<bdt class="statement-end-if-in-editor"></bdt> is provided for such update, in which case the terms of that new <bdt class="block-component"></bdt>license<bdt class="statement-end-if-in-editor"></bdt> will govern.</span></bdt>
                                                </bdt>
                                            </bdt></span></bdt>
                                </bdt>
                            </bdt></span><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                                <bdt class="statement-end-if-in-editor">
                                    <bdt class="block-component"></bdt>
                                </bdt>
                            </span></span></span></span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">2<bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                            <bdt data-type="conditional-block">
                                <bdt data-type="body"><span style="color: rgb(89, 89, 89); font-size: 14.6667px;">.<bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body"><span style="color: rgb(89, 89, 89); font-size: 14.6667px;">3  You may not share or make the Licensed Application available to third parties (unless to the degree allowed by the Usage Rules, and with <bdt class="question">Steal A Minute, Inc.</bdt>'s prior written consent), sell, rent, lend, lease or otherwise redistribute the Licensed Application.<span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Poppins;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                                                                        <bdt class="statement-end-if-in-editor"></bdt>
                                                                    </span></span></span></span>
                                                        <bdt class="block-component"></bdt>
                                                    </span></bdt>
                                            </bdt>
                                        </bdt></span></bdt>
                            </bdt>
                        </bdt></span></span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">2<bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">.<bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">4  You may not reverse engineer, translate, disassemble, integrate, decompile, remove, modify, combine, create derivative works or updates of, adapt, or attempt to derive the source code of the Licensed Application, or any part thereof (except with <bdt class="question">Steal A Minute, Inc.</bdt>'s prior written consent).<span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                        <bdt data-type="conditional-block">
                                                                            <bdt data-type="body"><span style="color: rgb(89, 89, 89); font-size: 14.6667px;">
                                                                                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                        <bdt data-type="conditional-block">
                                                                                            <bdt data-type="body"><span style="color: rgb(89, 89, 89); font-size: 14.6667px;">
                                                                                                    <bdt class="statement-end-if-in-editor">
                                                                                                        <bdt class="block-component"></bdt>
                                                                                                    </bdt>
                                                                                                </span></bdt>
                                                                                        </bdt>
                                                                                    </bdt>
                                                                                </span></bdt>
                                                                        </bdt>
                                                                    </bdt>
                                                                </span></span></span></bdt>
                                                </bdt>
                                            </bdt></span></bdt>
                                </bdt>
                            </bdt></span></span></span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">2<bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">.<bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">5  You may not copy (excluding when expressly <bdt class="block-component"></bdt>authorized<bdt class="statement-end-if-in-editor"></bdt> by this <bdt class="block-component"></bdt>license<bdt class="statement-end-if-in-editor"></bdt> and the Usage Rules) or alter the Licensed Application or portions thereof. You may create and store copies only on devices that You own or control for backup keeping under the terms of this <bdt class="block-component"></bdt>license<bdt class="statement-end-if-in-editor"></bdt>, the Usage Rules, and any other terms and conditions that apply to the device or software used. You may not remove any intellectual property notices. You acknowledge that no <bdt class="block-component"></bdt>unauthorized<bdt class="statement-end-if-in-editor"></bdt> third parties may gain access to these copies at any time. If you sell your Devices to a third party, you must remove the Licensed Application from the Devices before doing so.<span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                <bdt class="statement-end-if-in-editor"></bdt>
                                                            </span>
                                                            <bdt class="block-component"></bdt>
                                                        </span></bdt>
                                                </bdt>
                                            </bdt></span></bdt>
                                </bdt>
                            </bdt></span></span></span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">2<bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">.<bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">6  Violations of the obligations mentioned above, as well as the attempt of such infringement, may be subject to prosecution and damages.<span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                        <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                            <bdt data-type="conditional-block">
                                                                                <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                                                        <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                            <bdt data-type="conditional-block">
                                                                                                <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                                                                        <bdt class="statement-end-if-in-editor">
                                                                                                            <bdt class="block-component"></bdt>
                                                                                                        </bdt>
                                                                                                    </span></bdt>
                                                                                            </bdt>
                                                                                        </bdt>
                                                                                    </span></bdt>
                                                                            </bdt>
                                                                        </bdt>
                                                                    </span></span></span></span></bdt>
                                                </bdt>
                                            </bdt></span></bdt>
                                </bdt>
                            </bdt></span></span></span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">2<bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">.<bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">7  Licensor reserves the right to modify the terms and conditions of licensing.<span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                <bdt class="statement-end-if-in-editor">
                                                                    <bdt class="block-component"></bdt>
                                                                </bdt>
                                                            </span></span></bdt>
                                                </bdt>
                                            </bdt></span></bdt>
                                </bdt>
                            </bdt></span></span></span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">2<bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">.<bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">8  Nothing in this <bdt class="block-component"></bdt>license<bdt class="statement-end-if-in-editor"></bdt> should be interpreted to restrict third-party terms. When using the Licensed Application, You must ensure that You comply with applicable third-party terms and conditions.<span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                <bdt class="statement-end-if-in-editor"></bdt>
                                                            </span><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                        <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                            <bdt data-type="conditional-block">
                                                                                <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                                                        <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                            <bdt data-type="conditional-block">
                                                                                                <bdt data-type="body"><span style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89); font-size: 14.6667px;"></span></span></bdt>
                                                                                            </bdt>
                                                                                        </bdt>
                                                                                    </span></bdt>
                                                                            </bdt>
                                                                        </bdt>
                                                                    </span></span></span></span></bdt>
                                                </bdt>
                                            </bdt></span></bdt>
                                </bdt>
                            </bdt></span></span></span></div>
        </div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 1.5;"><br></div>
        <div align="center" class="MsoNormal" style="text-align: left; line-height: 150%;">
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" id="requirements" style="line-height: 17.25px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>3. TECHNICAL REQUIREMENTS</strong></span></span></strong></span></strong></span></strong></div>
            <div class="MsoNormal" style="line-height:115%;"><span style="font-size: 15px;">
                    <bdt class="block-component">
                        <bdt class="block-component">
                            <bdt class="block-component"></bdt>
                        </bdt>
                    </bdt>
                </span></span></bdt>
                </bdt>
                </bdt></span></bdt>
                </bdt>
                </bdt></span></span></span></span></bdt>
                </bdt>
                </bdt></span></bdt>
                </bdt>
                </bdt></span></span></span></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">3.<bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">1  You acknowledge that it is Your responsibility to confirm and determine that the app end-user device on which You intend to use the Licensed Application satisfies the technical specifications mentioned above.<span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                        <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                            <bdt data-type="conditional-block">
                                                                                <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                                                        <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                            <bdt data-type="conditional-block">
                                                                                                <bdt data-type="body"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                                                                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                                                        <bdt data-type="conditional-block">
                                                                                                                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                                                                                                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                                                                        <bdt data-type="conditional-block">
                                                                                                                                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;">
                                                                                                                                                        <bdt class="statement-end-if-in-editor">
                                                                                                                                                            <bdt class="statement-end-if-in-editor">
                                                                                                                                                                <bdt class="statement-end-if-in-editor">
                                                                                                                                                                    <bdt class="block-component"></bdt>
                                                                                                                                                                </bdt>
                                                                                                                                                            </bdt>
                                                                                                                                                        </bdt>
                                                                                                                                                    </span></span></bdt>
                                                                                                                                        </bdt>
                                                                                                                                    </bdt>
                                                                                                                                </span></bdt>
                                                                                                                        </bdt>
                                                                                                                    </bdt>
                                                                                                                </span></span></span></span></bdt>
                                                                                            </bdt>
                                                                                        </bdt>
                                                                                    </span></bdt>
                                                                            </bdt>
                                                                        </bdt>
                                                                    </span></span></span></span></bdt>
                                                </bdt>
                                            </bdt></span></bdt>
                                </bdt>
                            </bdt>
                        </span></span></span></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">3.<bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">2  Licensor reserves the right to modify the technical specifications as it sees appropriate at any time.<span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                        <bdt data-type="conditional-block">
                                                                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                                                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                        <bdt data-type="conditional-block">
                                                                                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                                                <bdt data-type="conditional-block">
                                                                                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                                                                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                                                                <bdt data-type="conditional-block">
                                                                                                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                                                                                                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                                                                                        <bdt data-type="conditional-block">
                                                                                                                                                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                                                                                                                                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                                                                                                        <bdt data-type="conditional-block">
                                                                                                                                                                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                                                                                                                                                    <bdt class="statement-end-if-in-editor">
                                                                                                                                                                                        <bdt class="statement-end-if-in-editor">
                                                                                                                                                                                            <bdt class="statement-end-if-in-editor">
                                                                                                                                                                                                <bdt class="statement-end-if-in-editor"></bdt>
                                                                                                                                                                                            </bdt>
                                                                                                                                                                                        </bdt>
                                                                                                                                                                                    </bdt>
                                                                                                                                                                                </span></bdt>
                                                                                                                                                                        </bdt>
                                                                                                                                                                    </bdt>
                                                                                                                                                                </span></bdt>
                                                                                                                                                        </bdt>
                                                                                                                                                    </bdt>
                                                                                                                                                </span></span></span></bdt>
                                                                                                                                </bdt>
                                                                                                                            </bdt>
                                                                                                                        </span></bdt>
                                                                                                                </bdt>
                                                                                                            </bdt>
                                                                                                        </span></span></span></bdt>
                                                                                        </bdt>
                                                                                    </bdt>
                                                                                </span></bdt>
                                                                        </bdt>
                                                                    </bdt>
                                                                </span></span></span></bdt>
                                                </bdt>
                                            </bdt></span></bdt>
                                </bdt>
                            </bdt>
                        </span></span></span></div>
            <div class="MsoNormal" style="line-height:115%;">
                <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" id="support" style="line-height: 17.25px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>4. MAINTENANCE AND SUPPORT</strong></span></span></strong></span></strong></span></strong></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">4.1  The Licensor is solely responsible for providing any maintenance and support services for this Licensed Application. You can reach the Licensor at the email address listed in the <bdt class="block-component"></bdt>App Store<bdt class="statement-end-if-in-editor"></bdt>
                    <bdt class="block-component"></bdt> or <bdt class="statement-end-if-in-editor"></bdt>
                    <bdt class="block-component"></bdt>Play Store<bdt class="statement-end-if-in-editor"></bdt> Overview for this Licensed Application.
                </span></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="line-height: 16.8667px;"><span style="line-height: 16.8667px;"><span style="line-height: 16.8667px;"><span style="line-height: 16.8667px;">
                                <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                    <bdt data-type="conditional-block">
                                        <bdt data-type="body">
                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body">
                                                        <font color="#595959"><span style="font-size: 15px;">4.2 </span></font><span style="font-size: 15px;"> <bdt class="question">Steal A Minute, Inc.</bdt> </span>
                                                        <font color="#595959"><span style="font-size: 15px;">and the End-User acknowledge that the Services have no obligation whatsoever to furnish any maintenance and support services with respect to the Licensed Application.<bdt class="else-block"></bdt>
                                                    </bdt>
                            </span></font>
                            </bdt>
                            </bdt>
                            </bdt>
                            </bdt>
                            </bdt>
                            </bdt></span></span></div>
            <div class="MsoNormal" style="line-height:115%;"><span style="font-size: 15px;">
                    <bdt class="block-component"></bdt>
                </span></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" id="datause" style="line-height: 17.25px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>5. USE OF DATA</strong></span></span></strong></span></strong></span></strong></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">You acknowledge that Licensor will be able to access and adjust Your downloaded Licensed Application content and Your personal information, and that Licensor's use of such material and information is subject to Your legal agreements with Licensor and Licensor's privacy policy<bdt class="block-component"></bdt>: <bdt class="question"><a href="https://heist.com/en/privacy/" target="_blank" data-custom-class="link">https://heist.com/en/privacy/</a></bdt>
                                                            <bdt class="block-component"></bdt>.
                                                        </span></bdt>
                                                </bdt>
                                            </bdt>
                                        </span></bdt>
                                </bdt>
                            </bdt>
                        </span></span></span></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">You acknowledge that the Licensor may periodically collect and use technical data and related information about your device, system, and application software, and peripherals, offer product support, facilitate the software updates, and for purposes of providing other services to you (if any) related to the Licensed Application. Licensor may also use this information to improve its products or to provide services or technologies to you, as long as it is in a form that does not personally identify you.</span><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                            <bdt class="block-container if" data-type="if">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;">
                                                                <bdt class="statement-end-if-in-editor"></bdt>
                                                            </span></span></bdt>
                                                </bdt>
                                            </bdt>
                                        </span></bdt>
                                </bdt>
                            </bdt>
                        </span></span></span></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" id="ugc" style="line-height: 17.25px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>6. USER-GENERATED CONTRIBUTIONS</strong></span></span></strong></span></strong></span></strong></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                            <bdt class="block-component"></bdt>The Licensed Application may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or in the Licensed Application, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, <bdt class="block-component"></bdt>"Contributions"<bdt class="statement-end-if-in-editor"></bdt>). Contributions may be viewable by other users of the Licensed Application and through third-party websites or applications. As such, any Contributions you transmit may be treated as non-confidential and non-proprietary. When you create or make available any Contributions, you thereby represent and warrant that:<bdt class="else-block"></bdt>
                                                        </span></bdt>
                                                </bdt>
                                            </bdt>
                                        </span></bdt>
                                </bdt>
                            </bdt>
                        </span></span></span></span></bdt>
                </bdt>
                </bdt></span></bdt>
                </bdt>
                </bdt></span></span></span></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size: 15px;">1. The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.</span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size: 15px;">2. You are the creator and owner of or have the necessary <bdt class="block-component"></bdt>licenses<bdt class="statement-end-if-in-editor"></bdt>, rights, consents, releases, and permissions to use and to <bdt class="block-component"></bdt>authorize<bdt class="statement-end-if-in-editor"></bdt> us, the Licensed Application, and other users of the Licensed Application to use your Contributions in any manner contemplated by the Licensed Application and this <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement.</span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size: 15px;">3. You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness or each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Licensed Application and this <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement.</span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size: 15px;">4. Your Contributions are not false, inaccurate, or misleading.</span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size: 15px;">5. Your Contributions are not unsolicited or <bdt class="block-component"></bdt>unauthorized<bdt class="statement-end-if-in-editor"></bdt> advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.</span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size: 15px;">6. Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, <bdt class="block-component"></bdt>libelous<bdt class="statement-end-if-in-editor"></bdt>, slanderous, or otherwise objectionable (as determined by us).</span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size: 15px;">7. Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size: 15px;">8. Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.</span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size: 15px;">9. Your Contributions do not violate any applicable law, regulation, or rule.</span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size: 15px;">10. Your Contributions do not violate the privacy or publicity rights of any third party.</span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size: 15px;">11. Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.</span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size: 15px;">12. Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.</span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size: 15px;">13. Your Contributions do not otherwise violate, or link to material that violates, any provision of this <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement, or any applicable law or regulation.</span></div>
            <div class="MsoNormal" style="line-height: 1; margin-left: 20px;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">Any use of the Licensed Application in violation of the foregoing violates this <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement and may result in, among other things, termination or suspension of your rights to use the Licensed Application.</span></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" id="contribution" style="line-height: 17.25px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>7. CONTRIBUTION <bdt class="block-component"></bdt>LICENSE<bdt class="statement-end-if-in-editor"></bdt></strong></span></span></strong></span></strong></span></strong></div>
            <div class="MsoNormal" style="line-height: 1.5;">
                <bdt class="block-component"></bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">By posting your Contributions to any part of the Licensed Application or making Contributions accessible to the Licensed Application by linking your account from the Licensed Application to any of your social networking accounts, you automatically grant, and you represent and warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and <bdt class="block-component"></bdt>license<bdt class="statement-end-if-in-editor"></bdt> to host, use copy, reproduce, disclose, sell, resell, publish, broad cast, retitle, archive, store, cache, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Contributions (including, without limitation, your image and voice) for any purpose, commercial advertising, or otherwise, and to prepare derivative works of, or incorporate in other works, such as Contributions, and grant and <bdt class="block-component"></bdt>authorize sublicenses<bdt class="statement-end-if-in-editor"></bdt> of the foregoing. The use and distribution may occur in any media formats and through any media channels.</span></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">This <bdt class="block-component"></bdt>license<bdt class="statement-end-if-in-editor"></bdt> will apply to any form, media, or technology now known or hereafter developed, and includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide. You waive all moral rights in your Contributions, and you warrant that moral rights have not otherwise been asserted in your Contributions.</span></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area in the Licensed Application. You are solely responsible for your Contributions to the Licensed Application and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.</span></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Contributions; (2) to <bdt class="block-component"></bdt>recategorize<bdt class="statement-end-if-in-editor"></bdt> any Contributions to place them in more appropriate locations in the Licensed Application; and (3) to prescreen or delete any Contributions at any time and for any reason, without notice. We have no obligation to monitor your Contributions.<bdt class="else-block"></bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" id="liability" style="line-height: 17.25px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>8. LIABILITY</strong></span></span></strong></span></strong></span></strong></div>
            <div class="MsoNormal" style="line-height:115%;"><span style="font-size: 15px;">
                    <bdt class="block-component"></bdt>
                </span></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                            <bdt class="block-component"></bdt>8<bdt class="else-block"></bdt>.1  Licensor's responsibility in the case of violation of obligations and tort shall be limited to intent and gross negligence. Only in case of a breach of essential contractual duties (cardinal obligations), Licensor shall also be liable in case of slight negligence. In any case, liability shall be limited to the foreseeable, contractually typical damages. The limitation mentioned above does not apply to injuries to life, limb, or health.<span style="font-size: 15px;">
                                                                <bdt class="statement-end-if-in-editor">
                                                                    <bdt class="block-component"></bdt>
                                                                </bdt>
                                                            </span>
                                                        </span></bdt>
                                                </bdt>
                                            </bdt>
                                        </span></bdt>
                                </bdt>
                            </bdt>
                        </span></span></span></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                            <bdt class="block-component"></bdt>8<bdt class="else-block"></bdt>.2  Licensor takes no accountability or responsibility for any damages caused due to a breach of duties according to Section 2 of this <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement. To avoid data loss, You are required to make use of backup functions of the Licensed Application to the extent allowed by applicable third-party terms and conditions of use. You are aware that in case of alterations or manipulations of the Licensed Application, You will not have access to the Licensed Application.<span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                        <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                            <bdt data-type="conditional-block">
                                                                                <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                                                        <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                            <bdt data-type="conditional-block">
                                                                                                <bdt data-type="body"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;">
                                                                                                            <bdt class="statement-end-if-in-editor">
                                                                                                                <bdt class="statement-end-if-in-editor">
                                                                                                                    <bdt class="block-component"></bdt>
                                                                                                                    <bdt class="forloop-component"></bdt>
                                                                                                                </bdt>
                                                                                                            </bdt>
                                                                                                        </span></span></bdt>
                                                                                            </bdt>
                                                                                        </bdt>
                                                                                    </span></bdt>
                                                                            </bdt>
                                                                        </bdt>
                                                                    </span></span></span>
                                                        </span></bdt>
                                                </bdt>
                                            </bdt>
                                        </span></bdt>
                                </bdt>
                            </bdt>
                        </span></span></span></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                <bdt data-type="conditional-block">
                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                            <bdt class="block-component"></bdt>8<bdt class="else-block"></bdt>.3  Licensor takes no accountability and responsibility in case of <span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                        <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                            <bdt data-type="conditional-block">
                                                                                <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                                                        <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                            <bdt data-type="conditional-block">
                                                                                                <bdt data-type="body"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                                                                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                                                        <bdt data-type="conditional-block">
                                                                                                                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                                                                                                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                                                                        <bdt data-type="conditional-block">
                                                                                                                                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;">
                                                                                                                                                        <bdt class="statement-end-if-in-editor">
                                                                                                                                                            <bdt class="statement-end-if-in-editor">
                                                                                                                                                                <bdt class="question">The company expressly disclaims all liability for any actions, conduct, or content posted, shared, or otherwise disseminated by users of the application. We make no representations or warranties regarding the accuracy, legality, or appropriateness of any user-generated content and take no responsibility for such content. Users assume all risks associated with their activities and interactions on the platform. The company shall not be liable for any damages or losses arising from users' use of the application or from any user-generated content. This disclaimer applies to the fullest extent permissible by law.</bdt>.<bdt class="forloop-component"></bdt>
                                                                                                                                                            </bdt>
                                                                                                                                                        </bdt>
                                                                                                                                                    </span></span></bdt>
                                                                                                                                        </bdt>
                                                                                                                                    </bdt>
                                                                                                                                </span></bdt>
                                                                                                                        </bdt>
                                                                                                                    </bdt>
                                                                                                                </span></span></span></span></bdt>
                                                                                            </bdt>
                                                                                        </bdt>
                                                                                    </span></bdt>
                                                                            </bdt>
                                                                        </bdt>
                                                                        <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                            <bdt data-type="conditional-block">
                                                                                <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                                                        <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                            <bdt data-type="conditional-block">
                                                                                                <bdt data-type="body"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                                                                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                                                        <bdt data-type="conditional-block">
                                                                                                                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                                                                                                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                                                                        <bdt data-type="conditional-block">
                                                                                                                                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;">
                                                                                                                                                        <bdt class="statement-end-if-in-editor">
                                                                                                                                                            <bdt class="statement-end-if-in-editor">
                                                                                                                                                                <bdt class="statement-end-if-in-editor"></bdt>
                                                                                                                                                            </bdt>
                                                                                                                                                        </bdt>
                                                                                                                                                    </span></span></bdt>
                                                                                                                                        </bdt>
                                                                                                                                    </bdt>
                                                                                                                                </span></bdt>
                                                                                                                        </bdt>
                                                                                                                    </bdt>
                                                                                                                </span></span></span></span></bdt>
                                                                                            </bdt>
                                                                                        </bdt>
                                                                                    </span></bdt>
                                                                            </bdt>
                                                                        </bdt>
                                                                    </span></span></span>
                                                        </span></bdt>
                                                </bdt>
                                            </bdt>
                                        </span></bdt>
                                </bdt>
                            </bdt>
                        </span></span></span></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" id="warranty" style="line-height: 17.25px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>9. WARRANTY</strong></span></span></strong></span></strong></span></strong></div>
        </div>
    </div>
    <div style="text-align: left; line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                    <bdt class="block-component"></bdt>9<bdt class="else-block"></bdt>.1  Licensor warrants that the Licensed Application is free of spyware, trojan horses, viruses, or any other malware at the time of Your download. Licensor warrants that the Licensed Application works as described in the user documentation.
                </span></span></span></div>
    <div style="line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                        <bdt data-type="conditional-block">
                                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                    <bdt class="block-component"></bdt>9<bdt class="else-block"></bdt>.2  No warranty is provided for the Licensed Application that is not executable on the device, that has been <bdt class="block-component"></bdt>unauthorizedly<bdt class="statement-end-if-in-editor"></bdt> modified, handled inappropriately or culpably, combined or installed with inappropriate hardware or software, used with inappropriate accessories, regardless if by Yourself or by third parties, or if there are any other reasons outside of <bdt class="question">Steal A Minute, Inc.</bdt>'s sphere of influence that affect the executability of the Licensed Application.
                                                </span></bdt>
                                        </bdt>
                                    </bdt>
                                </span></bdt>
                        </bdt>
                    </bdt>
                </span></span></span></div>
    <div style="line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                        <bdt data-type="conditional-block">
                                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                    <bdt class="block-component"></bdt>9<bdt class="else-block"></bdt>.3  You are required to inspect the Licensed Application immediately after installing it and notify <bdt class="question">Steal A Minute, Inc.</bdt> about issues discovered without delay by email provided in
                                                </span></bdt>
                                        </bdt>
                                    </bdt>
                                </span></bdt>
                        </bdt>
                    </bdt>
                </span></span> </span><a data-custom-class="link" href="#contact"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                        <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                            <bdt data-type="conditional-block">
                                <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                        <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body"><span style="color: rgb(89, 89, 89);">Contact Information</span></bdt>
                                            </bdt>
                                        </bdt>
                                    </span></bdt>
                            </bdt>
                        </bdt>
                    </span></span></span></a><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                        <bdt data-type="conditional-block">
                                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);">. The defect report will be taken into consideration and further investigated if it has been emailed within a period of <bdt class="question">seven (7)</bdt> days after discovery.</span></bdt>
                                        </bdt>
                                    </bdt>
                                </span></bdt>
                        </bdt>
                    </bdt>
                </span></span></span></div>
    <div style="line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                    <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                        <bdt data-type="conditional-block">
                                            <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                    <bdt class="block-component"></bdt>9<bdt class="else-block"></bdt>.4  If we confirm that the Licensed Application is defective, <bdt class="question">Steal A Minute, Inc.</bdt> reserves a choice to remedy the situation either by means of solving the defect or substitute delivery.
                                                </span></bdt>
                                        </bdt>
                                    </bdt>
                                </span></bdt>
                        </bdt>
                    </bdt>
                </span></span></span></div>
    <div style="line-height: 1;"><br></div>
    <div style="text-align: left; line-height: 1;">
        <bdt class="block-container if" data-type="if" id="36ce5a69-4560-4947-dc72-46e53e2d562a">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                    <bdt class="block-component"></bdt>9<bdt class="else-block"></bdt>.5 
                                                </span></span></span></span></span> </span>In the event of any failure of the Licensed Application to conform to any applicable warranty, You may notify the Services Store Operator, and Your Licensed Application purchase price will be refunded to You. To the maximum extent permitted by applicable law, the Services Store Operator will have no other warranty obligation whatsoever with respect to the Licensed Application, and any other losses, claims, damages, liabilities, expenses, and costs attributable to any negligence to adhere to any warranty.</span></div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div style="text-align: left; line-height: 1;"><br></div>
    <div style="text-align: left; line-height: 1.5;">
        <bdt class="block-container if" data-type="if" id="14038561-dad7-be9d-370f-f8aa487b2570">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                    <bdt class="block-component"></bdt>9<bdt class="else-block"></bdt>.6
                                                </span></span></span></span></span>  </span>If the user is an entrepreneur, any claim based on faults expires after a statutory period of limitation amounting to twelve (12) months after the Licensed Application was made available to the user. The statutory periods of limitation given by law apply for users who are consumers.</span></div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div style="text-align: left; line-height: 1.5;">
        <bdt class="block-container if" data-type="if" id="14038561-dad7-be9d-370f-f8aa487b2570">
            <bdt data-type="conditional-block">
                <bdt data-type="body"> </bdt> 
            </bdt> 
        </bdt>
    </div>
    <div style="text-align: left; line-height: 1.5;"><br></div>
    <div style="text-align: left; line-height: 1;">
        <div class="MsoNormal" data-custom-class="heading_1" id="productclaims" style="line-height: 115%;"><a name="_wj13r09u8u3u"></a><strong><span id="productclaims" style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>10. PRODUCT CLAIMS</strong></span></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></div>
    </div>
    <div style="text-align: left; line-height: 1;"><br></div>
    <div style="text-align: left; line-height: 1;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <bdt class="question"><span style="font-size: 15px;">Steal A Minute, Inc.</span></bdt><span style="font-size: 15px;"> </span><span style="font-size: 15px; line-height: 115%; font-family: Poppins; color: rgb(89, 89, 89);">and the End-User acknow</span><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">ledge that <bdt class="question">Steal A Minute, Inc.</bdt>, and not the Services, is responsible for addressing any claims of the End-User or any third party relating to the Licensed Application or the End-User’s possession and/or use of that Licensed Application, including, but not limited to:</span>
        </div>
    </div>
    <div style="text-align: left; line-height: 1;"><br></div>
    <div style="text-align: left; line-height: 1;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">(i) product liability claims;</span></div> 
                </bdt> 
            </bdt> 
        </bdt>
    </div>
    <div style="text-align: left; line-height: 1;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">(ii) any claim that the Licensed Application fails to conform to any applicable legal or regulatory requirement; and</span></div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div style="text-align: left; line-height: 1;"><br></div>
    <div style="text-align: left; line-height: 1;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">(iii) claims arising under consumer protection, privacy, or similar legislation<bdt class="block-component"></bdt><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">, including in connection with Your Licensed Application’s use of the HealthKit and HomeKit</span>
                            <bdt class="statement-end-if-in-editor"></bdt>.
                        </span></div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div style="text-align: left; line-height: 1.5;"><br></div>
    <div style="text-align: left; line-height: 1.5;"><br></div>
    <div style="text-align: left; line-height: 1;">
        <bdt class="block-container if" data-type="if" id="87a7471d-cf82-1032-fdf8-601d37d7b017">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="heading_1" id="compliance" style="line-height: 17.25px;"><strong><span style="line-height: 24.5333px; color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>11. LEGAL COMPLIANCE</strong></span></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div style="text-align: left; line-height: 1;"><br></div>
    <div style="text-align: left; line-height: 1;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="body_text" style="font-size: 14.6667px; line-height: 1.5;"><span style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);">You represent and warrant that You are not located in a country that is subject to a US Government embargo, or that has been designated by the US Government as a <bdt class="block-component"></bdt>"terrorist supporting"<bdt class="statement-end-if-in-editor"></bdt> country; and that You are not listed on any US Government list of prohibited or restricted parties.</span></div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="text-align: left; line-height: 1;">
        <bdt class="block-container if" data-type="if" id="923fc4bc-b171-82ba-b6eb-0a13c12d1b6b">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="heading_1" id="contact" style="line-height: 115%;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 24.5333px; color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>12. CONTACT INFORMATION</strong></span></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div style="text-align: left; line-height: 1;"><br></div>
    <div style="text-align: left; line-height: 1;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">For general inquiries, complaints, questions or claims concerning the Licensed Application, please contact:</span></div>       <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                            <bdt class="question">Heist Support</bdt>
                            <bdt class="block-component"></bdt>
                        </span></div>
                </bdt>
            </bdt>
        </bdt>
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                            <bdt class="question">38 Glen Cove Rd</bdt>
                            <bdt class="block-component"></bdt>
                        </span></div>
                </bdt>
            </bdt>
        </bdt>
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                            <bdt class="question">Greenvale</bdt>, <bdt class="block-component"></bdt>
                            <bdt class="block-component"></bdt>
                            <bdt class="question">NY</bdt>
                            <bdt class="statement-end-if-in-editor"></bdt>
                            <bdt class="block-component"></bdt>
                            <bdt class="question">11548</bdt>
                        </span></span></div>
                </bdt>
            </bdt>
        </bdt>
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">
                            <bdt class="block-component"></bdt>
                            <bdt class="block-component"></bdt>
                            <bdt class="question">United States</bdt>
                            <bdt class="statement-end-if-in-editor"></bdt>
                            <bdt class="block-component"></bdt>
                            <bdt class="else-block"></bdt>
                        </span>
                </bdt></span>
    </div>
    </bdt>
    </bdt>
    </bdt>
    <bdt class="block-container if" data-type="if">
        <bdt data-type="conditional-block">
            <bdt data-type="body">
                <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                        <bdt class="question">support@heist.com</bdt>
                    </span></div>
            </bdt>
        </bdt>
    </bdt>
</div>
<div style="text-align: left; line-height: 1.5;"><br></div>
<div style="text-align: left; line-height: 1.5;"><br></div>
<div style="text-align: left; line-height: 1;">
    <bdt class="block-container if" data-type="if">
        <bdt data-type="conditional-block">
            <bdt data-type="body">
                <div class="MsoNormal" data-custom-class="heading_1" id="termination" style="line-height: 1.5;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 24.5333px; color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>13. TERMINATION</strong></span></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></div>
            </bdt>
        </bdt>
    </bdt>
</div>
<div style="text-align: left; line-height: 1;"><br></div>
<div style="text-align: left; line-height: 1;">
    <bdt class="block-container if" data-type="if">
        <bdt data-type="conditional-block">
            <bdt data-type="body">
                <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">The <bdt class="block-component"></bdt>license<bdt class="statement-end-if-in-editor"></bdt> is valid until terminated by <bdt class="question">Steal A Minute, Inc.</bdt> or by You. Your rights under this <bdt class="block-component"></bdt>license<bdt class="statement-end-if-in-editor"></bdt> will terminate automatically and without notice from <bdt class="question">Steal A Minute, Inc.</bdt> if You fail to adhere to any term(s) of this <bdt class="block-component"></bdt>license<bdt class="statement-end-if-in-editor"></bdt>. Upon <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> termination, You shall stop all use of the Licensed Application, and destroy all copies, full or partial, of the Licensed Application.</span></div>
            </bdt>
        </bdt>
    </bdt>
</div>
<div style="text-align: left; line-height: 1.5;">
    <bdt class="block-container if" data-type="if">  <bdt data-type="conditional-block">  <bdt data-type="body">  <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            </bdt>
        </bdt>
    </bdt>
</div>
<div style="text-align: left;">
    <div class="MsoNormal" data-custom-class="heading_1" id="thirdparty" style="line-height: 1.5;"><a name="_k3mndam4w6w1"></a><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 24.5333px; color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</strong></span></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></div>
</div>
<div style="text-align: left; line-height: 1;"><br></div>
<div style="text-align: left;">
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px; line-height: 115%; font-family: Poppins; color: rgb(89, 89, 89);">
            <bdt class="question">Steal A Minute, Inc.</bdt> represents and warrants that <bdt class="question">Steal A Minute, Inc.</bdt> will comply with applicable third-party terms of agreement when using Licensed Application.
        </span></div>
</div>
<div style="text-align: left; line-height: 1;"><br></div>
<div style="text-align: left;">
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">In Accordance with Section 9 of the <bdt class="block-component"></bdt>"Instructions for Minimum Terms of Developer's End-User License Agreement," <bdt class="statement-end-if-in-editor"></bdt>
            <bdt class="block-component"></bdt>both Apple and Google and their<bdt class="block-component"></bdt> subsidiaries shall be third-party beneficiaries of this End User <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement and — upon Your acceptance of the terms and conditions of this <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement, <bdt class="block-component"></bdt>both Apple and Google<bdt class="block-component"></bdt> will have the right (and will be deemed to have accepted the right) to enforce this End User <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement against You as a third-party beneficiary thereof.
        </span></div>
</div>
<div style="text-align: left; line-height: 1.5;"><br></div>
<div style="text-align: left;">
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="ipr" style="line-height: 1.5;"><a name="_e2dep1hfgltt"></a><strong><span style="line-height: 115%; font-family: Poppins;"><span style="font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 24.5333px; color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>15. INTELLECTUAL PROPERTY RIGHTS</strong></span></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></span></strong></div>
</div>
<div style="text-align: left; line-height: 1;"><br></div>
<div style="text-align: left;">
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
            <bdt class="question">Steal A Minute, Inc.</bdt> and the End-User acknowledge that, in the event of any third-party claim that the Licensed Application or the End-User's possession and use of that Licensed Application infringes on the third party's intellectual property rights, <bdt class="question">Steal A Minute, Inc.</bdt>, and not the Services, will be solely responsible for the investigation, <bdt class="block-component"></bdt>defense<bdt class="statement-end-if-in-editor"></bdt>, settlement, and discharge or any such intellectual property infringement claims.
        </span></div>
</div>
<div style="text-align: left; line-height: 1.5;"><br></div>
<div style="text-align: left;">
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="law" style="line-height: 1.5;"><a name="_p6vbf8atcwhs"></a><strong><span style="line-height: 115%; font-family: Poppins;"><span style="font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins;"><span style="font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 24.5333px; color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>16. APPLICABLE LAW</strong></span></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></span></strong></span></span></strong></div>
</div>
<div style="text-align: left; line-height: 1;"><br></div>
<div style="text-align: left;">
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">This <bdt class="block-component"></bdt>License<bdt class="statement-end-if-in-editor"></bdt> Agreement is governed by the laws of <bdt class="block-component"></bdt>
            <bdt class="block-component"></bdt>the State of <bdt class="question">New York</bdt>
            <bdt class="statement-end-if-in-editor"></bdt>
            <bdt class="block-component"></bdt> excluding its conflicts of law rules.
        </span></div>
</div>
<div style="text-align: left; line-height: 1.5;"><br></div>
<div style="text-align: left;">
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="urp" style="line-height: 1.5;"><a name="_v5i5tjw62cyw"></a><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins;"><span style="font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins;"><span style="font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 24.5333px; color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>17. USERNAME REVOCATION POLICY</strong></span></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></span></strong></span></span></strong></span></strong></div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" style="line-height: 1;">
        <bdt class="block-container if" data-type="if" id="4de367b8-a92e-8bf8-bc2e-013cba6337f8">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                <bdt data-type="conditional-block">
                                                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                                <bdt data-type="conditional-block">
                                                                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                                                                                                                <bdt class="block-component"></bdt>17<bdt class="else-block"></bdt><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                                                                                                                                <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                                                                                                                    <bdt data-type="conditional-block">
                                                                                                                                                                                        <bdt data-type="body"><span style="color: rgb(89, 89, 89);">.1</span></bdt>
                                                                                                                                                                                    </bdt>
                                                                                                                                                                                </bdt>
                                                                                                                                                                            </span></span></span></span>
                                                                                                                                                            </span></span></span></span></span></span></span></span></span></span></span></span></span></span></bdt>
                                                                                                </bdt>
                                                                                            </bdt>
                                                                                        </span></bdt>
                                                                                </bdt>
                                                                            </bdt>
                                                                        </span></span></span></span></span></span></span></span></span></span> </span> </span>The Platform reserves the absolute right, at its sole discretion, to revoke, reclaim, or reassign any username registered by a user if it determines, based on its judgment, that the username presents a risk of harm or misuse. Such determinations may include, but are not limited to, situations where the username: <br>
                                                                                <ol>
                                                                                        <li>Is used or has the potential to be used for the dissemination of false, misleading, or disinformation;</li>
                                                                                        <li>Creates or contributes to dangerous conditions, harassment, bullying, intimidation, or any other form of abuse;</li>
                                                                                        <li>Infringes upon trademarks, copyrights, or other intellectual property rights of a third party;</li>
                                                                                        <li>Could reasonably cause confusion, particularly in cases involving government entities, public service organizations, or other official bodies;</li>
                                                                                        <li>Violates any applicable law, regulation, or the Platform’s Terms and Conditions; or</li>
                                                                                        <li>Represents any other risk deemed actionable by the Platform.</li>
                                                                                </ol>
                                                                        </span></div> 
                </bdt> 
            </bdt> 
        </bdt>
        <bdt class="block-container if" data-type="if">  <bdt data-type="conditional-block">  <bdt data-type="body">       <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                <bdt class="block-component"></bdt>17<bdt class="else-block"></bdt><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                                <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                    <bdt data-type="conditional-block">
                                                                                        <bdt data-type="body"><span style="color: rgb(89, 89, 89);">.2</span></bdt>
                                                                                    </bdt>
                                                                                </bdt>
                                                                            </span></span></span></span>
                                                            </span></span></span></span></span></span></span> </span> </span>Users acknowledge and agree that the determination of risk or harm rests solely with the Platform and that the Platform shall not be obligated to provide prior notice or justification for the revocation or reassignment of a username. By using the Platform, users waive any claims or demands arising from the enforcement of this policy.<bdt class="block-component"></bdt></span></div>
                </bdt>
            </bdt>
        </bdt>
    </div>
</div>

<div style="text-align: left; line-height: 1.5;"><br></div>
<div style="text-align: left;">
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="misc" style="line-height: 1.5;"><a name="_v5i5tjw62cyw"></a><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins;"><span style="font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins;"><span style="font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 24.5333px; color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Poppins; font-size: 19px;"><span style="font-size: 19px;"><strong>18. MISCELLANEOUS</strong></span></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></strong></span></span></strong></span></span></strong></span></strong></div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" style="line-height: 1;">
        <bdt class="block-container if" data-type="if" id="4de367b8-a92e-8bf8-bc2e-013cba6337f8">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                <bdt data-type="conditional-block">
                                                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);">
                                                                                            <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                                <bdt data-type="conditional-block">
                                                                                                    <bdt data-type="body"><span style="color: rgb(89, 89, 89);"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                                                                                                                <bdt class="block-component"></bdt>18<bdt class="else-block"></bdt><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                                                                                                                                <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                                                                                                                    <bdt data-type="conditional-block">
                                                                                                                                                                                        <bdt data-type="body"><span style="color: rgb(89, 89, 89);">.1</span></bdt>
                                                                                                                                                                                    </bdt>
                                                                                                                                                                                </bdt>
                                                                                                                                                                            </span></span></span></span>
                                                                                                                                                            </span></span></span></span></span></span></span></span></span></span></span></span></span></span></bdt>
                                                                                                </bdt>
                                                                                            </bdt>
                                                                                        </span></bdt>
                                                                                </bdt>
                                                                            </bdt>
                                                                        </span></span></span></span></span></span></span></span></span></span> </span> </span>If any of the terms of this agreement should be or become invalid, the validity of the remaining provisions shall not be affected. Invalid terms will be replaced by valid ones formulated in a way that will achieve the primary purpose.</span></div> 
                </bdt> 
            </bdt> 
        </bdt>
        <bdt class="block-container if" data-type="if">  <bdt data-type="conditional-block">  <bdt data-type="body">       <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                <bdt class="block-component"></bdt>18<bdt class="else-block"></bdt><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                                <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                    <bdt data-type="conditional-block">
                                                                                        <bdt data-type="body"><span style="color: rgb(89, 89, 89);">.2</span></bdt>
                                                                                    </bdt>
                                                                                </bdt>
                                                                            </span></span></span></span>
                                                            </span></span></span></span></span></span></span> </span> </span>Collateral agreements, changes and amendments are only valid if laid down in writing. The preceding clause can only be waived in writing.<bdt class="block-component"></bdt></span></div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" style="line-height: 115%;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;font-family:Poppins;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                <bdt class="block-component"></bdt>18<bdt class="else-block"></bdt><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                                <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                                                                                    <bdt data-type="conditional-block">
                                                                                        <bdt data-type="body"><span style="color: rgb(89, 89, 89);">.3  <bdt class="question">Content and Conduct Policy: This application enforces a strict zero-tolerance policy against objectionable content and abusive behavior. Objectionable content includes, but is not limited to, any material that is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's privacy, or contains explicit or graphic descriptions or accounts of non-consensual sexual acts. Additionally, users are prohibited from engaging in abusive behavior, which includes harassment, bullying, and other disruptive or harmful actions towards other users. Violations of this policy may lead to immediate suspension or termination of your account, at our sole discretion, and we may report you to law enforcement authorities if the situation warrants.</bdt><span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                                                                    <bdt class="statement-end-if-in-editor"></bdt>
                                                                                                </span></span></bdt>
                                                                                    </bdt>
                                                                                </bdt>
                                                                            </span></span></span></span>
                                                            </span></span></span></span></span></span></span></span></span></span></div>
                </bdt>
            </bdt>
        </bdt>
    </div>
</div>



<style>
ul {
    list-style-type: square;
}

ul>li>ul {
    list-style-type: circle;
}

ul>li>ul>li>ul {
    list-style-type: square;
}

ol li {
    font-family: Poppins;
}
</style>
</div>
      `}
            ></Script>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Eula
